import * as ActionTypes from "../constants/actionTypes";
import { apiClient } from "../lib";

import store from "../store";

export const fetch = async (params = {}) => {
  const response = await apiClient.organizations.administrators.fetch();

  const data = response.data.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  store.dispatch({
    type: ActionTypes.FETCH_ADMINISTRATORS,
    payload: data,
  });
};

export const update = async (params = {}) => {
  const response = await apiClient.organizations.administrators.update(params);

  store.dispatch({
    type: ActionTypes.UPDATE_ADMINISTRATOR,
    payload: response.data,
  });
};

export const activate = async (ids = []) => {
  const response = await apiClient.organizations.administrators.activate({
    ids,
  });

  store.dispatch({
    type: ActionTypes.UPDATE_ADMINISTRATORS,
    payload: response.data,
  });

  return response.data;
};

export const create = async (params = {}) => {
  const response = await apiClient.organizations.administrators.create(params);

  store.dispatch({
    type: ActionTypes.CREATE_ADMINISTRATOR,
    payload: response.data,
  });

  return response.data;
};

export const invite = async (params = {}) => {
  params.skipAccountCreate = true;
  const response = await apiClient.organizations.administrators.create(params);

  if (response.data) {
    store.dispatch({
      type: ActionTypes.CREATE_ADMINISTRATOR,
      payload: response.data,
    });
  }

  return response.data;
};

export const findOrFetch = async (id) => {
  store.getState().administrators[id]; // || fetchOne(id)
};

export const destroy = async (options) => {
  // const response = await apiClient.organizations.administrators.destroy(options)
  // store.dispatch({
  //   type: ActionTypes.DESTROY_ADMINISTRATOR,
  //   payload: response.data
  // })
};

export const test = (account = {}) =>
  [
    "cwright@nextinline.io",
    "akorte@nextinline.io",
    "nkurgs@gmail.com",
    "nkurgansky@nextinline.io",
    "calvinrosehart@yahoo.com",
    "kblackman@nextinline.io",
    "murtaza@nextinline.io",
    "farooq@nextinline.io",
    "omar@nebulosastudio.com",
  ].includes(account.email);
